.root {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.notice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 70px;
  width: 100%;
  padding: 14px 90px;
  margin-bottom: 54px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
  box-shadow: var(--box-shadow-e);
  transform: translateY(200%);
  transition: all var(--transition);
}

.notice.show {
  transform: translateY(0);
}

.text {
  font-weight: 500;
}

.button {
  min-width: 276px;
}

@media (max-width: 1280px) {
  .notice {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 34px;
    padding-right: 34px;
    gap: 15px;
  }
}

@media (max-width: 1024px) {
  .notice {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 575px) {
  .notice {
    flex-direction: column;
    margin: 0 0 17px;
    padding: 9px 40px 12px;
    gap: 14px;
    text-align: center;
  }

  .button {
    min-width: 220px;
  }
}
