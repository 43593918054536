.root {
  padding-top: 40px;
  padding-bottom: 100px;
  text-align: center;
}

.title {
  max-width: 920px;
  margin: -80px auto 0;
  padding-top: 80px;
  font-size: 1.75rem;
  font-weight: 600;
}

.subtitle {
  max-width: 920px;
  margin: 0 auto;
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: 15px;
  color: var(--grey-1);
}

.title + .fields,
.subtitle + .fields {
  margin-top: 55px;
}

.buttons {
  margin-top: 58px;
}

.button {
  min-width: 276px;
}

.notice {
  max-width: 480px;
  margin: 10px auto 0;
  font-size: 12px;
  line-height: 1.2;
  color: var(--grey-2);
}

.notice a {
  color: inherit;
  text-decoration: underline;
}

.fields {
  display: flex;
  flex-direction: column;
  max-width: 542px;
  margin-left: auto;
  margin-right: auto;
  gap: 28px;
}

.inputContainer {
  width: 100%;
  flex-basis: 100%;
}

.inputContainer input {
  text-align: center;
}

.phoneInputInput {
  padding-right: 40px;
}

.result {
  max-width: 580px;
  margin: 0 auto;
  text-align: center;
}

.resultTitle {
  margin-bottom: 68px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 20px;
}

.resultSubtitle {
  max-width: 500px;
  margin: 0 auto 26px;
  font-weight: 500;
}

.root :global(.forma-ui__social-select__items) {
  margin-left: auto;
}

.modal {
  --input-border: var(--grey-3);
  --input-hover-border: var(--grey-2);
  --input-height: 44px;

  text-align: center;
}

.modalSubtitle {
  margin-bottom: 20px;
  color: var(--grey-1);
}

@media (max-width: 1280px) {
  .root {
    padding-top: 30px;
  }

  .title {
    margin-top: -60px;
    padding-top: 60px;
  }
}

@media (max-width: 1024px) {
  .root {
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  .root {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .title {
    margin-top: -50px;
    padding-top: 50px;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .subtitle {
    font-size: 18px;
    margin-bottom: 35px;
    font-weight: 400;
  }

  .fields {
    gap: 22px;
  }

  .fields > div {
    width: 100%;
    flex-basis: 100%;
  }

  .buttons {
    margin-top: 56px;
  }

  .notice {
    margin-top: 20px;
    font-size: 12px;
    color: var(--grey-2)
  }

  .buttons > * {
    width: 100%;
    font-size: 14px;
  }

  .result {
    margin-top: 30px;
  }

  .resultTitle {
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 600;
  }

  .resultSubtitle {
    font-size: 14px;
    font-weight: 400;
  }

  .root :global(.forma-ui__social-select__items) {
    margin-left: auto;
    margin-right: auto;
  }
}