.menuButton {
  padding: 6px;
  line-height: 0;
}

.closeButton {
  line-height: 0;
  margin-right: -10px;
  padding: 10px;
  color: var(--color-black-primary);
}

.closeButton svg {
  width: 20px;
  height: 20px;
}

.overlay {
  display: none;
}

.overlay.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 99;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 352px;
  display: flex;
  flex-direction: column;
  background: var(--color-white-primary);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  transform: translateX(110%);
  transition: transform var(--transition);
  z-index: 100;
}

.menu.open {
  transform: translateX(0);
}

.head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 30px;
  color: var(--grey-3);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 32px 35px;
  overflow-y: auto;
}

.title {
  display: block;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--grey-1);
}

.title.active {
  font-weight: 600;
}

.link {
  justify-content: flex-start;
  padding: 16px 4px;
  border-top: 1px solid var(--grey-4);
  border-radius: 0;
  color: var(--color-black-primary);
  font-size: 16px;
  line-height: 1.25;
  text-align: left;
  gap: 8px;
}

.link:hover {
  color: var(--primary-color-dark);
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  gap: 18px;
}

@media (max-width: 575px) {
  .menuButton {
    padding: 6px 0;
  }

  .menu {
    left: 0;
    width: 100%;
  }

  .head {
    padding: 10px 15px;
  }

  .content {
    padding: 0 15px 35px;
  }

  .items {
    margin-bottom: 20px;
    gap: 0;
  }

  .title {
    padding-top: 0;
  }

  .item {
    padding: 14px 0;
    color: var(--color-black-primary);
  }

  .item:not(:last-child) {
    border-bottom: 1px solid var(--grey-3);
  }

  .button {
    min-height: 0;
    padding: 12px 16px;
    font-size: 14px;
  }

  .logoIcon {
    width: 38px;
    height: 38px;
  }
}
