.modal {
  padding-left: 16px;
  padding-right: 16px;
}

.modalTitle {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
}

.modalTitleIcon {
  position: absolute;
  top: 0;
  left: 0;
}

.modalContent {
  display: flex;
  justify-content: center;
  min-height: 358px;
  min-width: 629px;
  gap: 46px;
}

.loader {
  margin-top: auto;
  margin-bottom: auto;
}

.modalButtons {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.timeHead {
  margin-bottom: 4px;
  font-family: var(--headings-font);
  font-size: 14px;
}

.timeItems {
  margin-top: 20px;
  width: 304px;
  min-height: 258px;
}

.modalButton {
  max-width: 276px;
  margin-left: auto;
  margin-right: auto;
}

.modalContent :global(.react-datepicker) {
  box-shadow: none;
  background: none;
  border: none;
}

.modalContent :global(.react-datepicker__current-month) {
  display: block;
}

.modalContent :global(.react-datepicker__header) {
  background: none;
  border: none;
  padding: 7px 0 0;
}

.modalContent :global(.react-datepicker__navigation) {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background: var(--grey-5);
  text-indent: 0;
}

.modalContent :global(.react-datepicker__navigation:hover),
.modalContent :global(.react-datepicker__navigation:focus) {
  background: var(--grey-4);
}

.modalContent :global(.react-datepicker__navigation--previous) {
  left: auto;
  right: 38px;
}

.modalContent :global(.react-datepicker__navigation-icon) {
  position: static;
  width: auto;
  line-height: 0;
}

.modalContent :global(.react-datepicker__navigation-icon::before) {
  display: none;
}

.modalContent :global(.react-datepicker__year-read-view--down-arrow),
.modalContent :global(.react-datepicker__month-read-view--down-arrow),
.modalContent :global(.react-datepicker__month-year-read-view--down-arrow) {
  border-color: var(--color-black-primary);
}

.modalContent :global(.react-datepicker__current-month) {
  text-transform: capitalize;
}

.modalContent :global(.react-datepicker__current-month),
.modalContent :global(.react-datepicker-time__header),
.modalContent :global(.react-datepicker-year-header) {
  text-align: left;
  font-family: var(--headings-font);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.modalContent :global(.react-datepicker__day-names) {
  margin-top: 14px;
}

.modalContent :global(.react-datepicker__day-name) {
  font-weight: 600;
}

.modalContent :global(.react-datepicker__day-name),
.modalContent :global(.react-datepicker__day),
.modalContent :global(.react-datepicker__time-name) {
  margin: 0;
  width: 38px;
  line-height: 38px;
  font-size: 14px;
  user-select: none;
  transition: var(--transition);
}

.modalContent :global(.react-datepicker__day) {
  border-radius: 10px;
  border: 1px solid var(--grey-4);
}

.modalContent :global(.react-datepicker__day--outside-month) {
  color: var(--grey-4)
}

.modalContent :global(.react-datepicker__day--today:not(.react-datepicker__day--selected)) {
  color: var(--color-green-primary);
}

@media (max-width: 800px) {
  .modal {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .timeItems {
    width: 230px;
  }
}

@media (max-width: 640px) {
  .modalContent {
    gap: 12px
  }
}

@media (max-width: 575px) {
  .modal {
    height: 65vh;
    height: 65dvh;
  }

  .modalContent {
    justify-content: center;
    height: 100%;
    min-width: 0;
    min-height: 0;
  }

  .modalButton {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .timeItems {
    width: 100%;
  }
}