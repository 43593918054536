.root {
  padding-top: 20px;
  padding-bottom: 40px;
  background: var(--color-white-primary);
}

.container {
  display: flex;
  gap: 35px;
}

.root.simple {
  background: none;
}

.root.simple .createWithForma {
  margin-left: auto;
  margin-right: auto;
}

.simple .logo {
  width: 32px;
  height: 32px;
}

.title {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
}

.item {
  margin-bottom: 16px;
}

.link {
  display: inline-flex;
  align-items: center;
  color: var(--grey-0);
  font-size: 14px;
  word-wrap: break-word;
}

.link:hover,
.link:focus {
  color: var(--color-black-primary);
}

.cursorDefault {
  cursor: default;
}

.linkIcon {
  flex-shrink: 0;
  margin-right: 10px;
  color: var(--color-black-primary);
  line-height: 0;
}

.description {
  max-width: 210px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.28;
  color: var(--grey-1);
}

.company {
  margin-right: auto;
}

.column {
  width: 150px;
}

.column:global(#materials) {
  width: 170px;
}

.column:global(#contacts) {
  width: 200px;
}

.createWithForma {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: var(--headings-font);
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 1280px) {
  .root {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .root {
    flex-wrap: wrap;
    margin-bottom: 12px;
  }

  .container {
    gap: 15px;
  }

  .column {
    width: 100px;
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    gap: 20px;
  }

  .column {
    width: 100%;
  }

  .title {
    font-size: 16px;
  }

  .createWithForma {
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
  }
}

@media (max-width: 575px) {
  .root {
    padding: 50px 0 40px;
  }
}
