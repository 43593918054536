.root.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  z-index: 30;
}

.header {
  padding: 10px 0;
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-e);
}

.header.simple {
  position: relative;
  width: 100%;
  margin-bottom: -57px;
  justify-content: center;
  background: none;
  box-shadow: none;
}

.header > div {
  display: flex;
  align-items: center;
}

.simple .logo {
  margin-left: auto;
  margin-right: auto;
}

.logo {
  margin-right: 25px;
  gap: 9px;
}

.logoIcon {
  width: 26px;
  height: 26px;
}

.logoText {
  width: 72px;
  font-size: 17px;
}

.links {
  display: flex;
  gap: 25px;
}

.link.link {
  padding: 6.5px 0;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--color-black-primary);
}

.link.link:hover,
.link.link:focus {
  color: var(--primary-color);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  gap: 10px;
}

.button {
  min-height: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 6.5px 16px;
  border-radius: var(--border-radius-s);
}

.buttonPhone {
  min-height: 0;
  padding: 6px 26px;
  font-weight: 400;
  color: var(--grey-1);
}

.buttonPhone:hover,
.buttonPhone:focus {
  color: var(--primary-color);
  background: var(--color-green-4);
}

.buttonMobile {
  min-height: 0;
  color: var(--grey-0);
}

.lang {
  margin-left: 5px;
}

.submenuWrapper {
  position: absolute;
  top: 40px;
  width: 100%;
  padding: 40px 0 30px;
  font-size: 0.875rem;
  background-color: var(--color-white-primary);
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-180px);
  transition: all 0.3s ease-in;
  z-index: -1;
}

.submenuWrapper > div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 10px;
}

.submenuWrapper .item {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}

.submenuWrapper .item:hover {
  color: var(--primary-color);
}

.show {
  transform: translateY(0);
  visibility: visible;
  pointer-events: all;
}

.navMobile {
  flex-grow: 1;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .links {
    gap: 12px;
  }

  .buttons {
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 10px 0;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  }

  .logo {
    margin-right: 10px;
  }

  .logoIcon {
    width: 38px;
    height: 38px;
  }

  .logoText {
    display: none;
  }

  .links {
    display: none;
  }

  .buttonMobile {
    padding: 4px 0;
    color: var(--grey-2);
  }
}
