.root {
  display: flex;
  gap: 8px;
  position: relative;
}

.button {
  color: var(--color-black-primary);
  padding: 5px;
}

.button:hover,
.button:focus {
  color: var(--primary-color);
}

.button > .buttonIcon {
  color: inherit;
  width: 24px;
  height: 24px;
}

.list {
  position: absolute;
  top: calc(100% + 19px);
  left: 50%;
  transform: translate(-50%, -10px);
  opacity: 0;
  visibility: hidden;
  transition: all var(--transition);
}

.item {
  margin-bottom: 6px;
}

.list.open {
  transform: translate(-50%, 0);
  opacity: 1;
  visibility: visible;
}

.link {
  display: inline-block;
  padding: 6px 20px;
  font-size: 18px;
  line-height: 1.34;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--grey-0);
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
  box-shadow: var(--box-shadow-e);
}

.link.active {
  color: var(--primary-color);
}

@media (max-width: 1280px) {
  .root {
    gap: 10px;
  }

  .link {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 575px) {
  .root {
    margin-right: 4px;
  }
}
