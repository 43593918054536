.root {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.link {
  border-radius: 0;
}

.link.active {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}
