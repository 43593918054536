:root {
  --color-black-primary: #161616;
  --color-white-primary: #fff;

  --color-black-transparent-25: rgba(0, 0, 0, 0.25);

  --grey-0: #555c61;
  --grey-1: #6e7072;
  --grey-2: #969c9e;
  --grey-3: #c6cbcd;
  --grey-4: #d3d7db;
  --grey-5: #dfe2e5;
  --grey-6: #ebeef1;
  --grey-7: #f3f5f6;

  --color-green-primary: #79be70;
  --color-green-light: #99d899;
  --color-green-dark: #6cb56c;
  --color-green-3: #d5edd5;
  --color-green-4: #e4f0e4;

  --color-red-bg: #ffbbbb;
  --color-red-primary: #e26565;
  --color-red-1: #d55050;
  --color-red-2: #ca3737;

  --primary-color: var(--color-green-primary);
  --primary-color-light: var(--color-green-light);
  --primary-color-dark: var(--color-green-dark);

  --success-color: var(--color-green-primary);
  --attention-color: rgb(255, 221, 108);
  --error-color: var(--color-red-primary);

  --border-radius-xxs: 4px;
  --border-radius-xs: 6px;
  --border-radius-s: 8px;
  --border-radius-m: 12px;
  --border-radius-l: 16px;
  --border-radius-xl: 20px;

  --box-shadow-a: 0px 0px 4px rgba(0, 0, 0, 0.04),
    0px 6px 12px rgba(0, 0, 0, 0.08);
  --box-shadow-b: 0px 0px 15px rgba(0, 0, 0, 0.1);
  --box-shadow-c: 0px 3px 6px rgba(105, 130, 166, 0.02),
    0px 0px 2px rgba(47, 58, 74, 0.02);
  --box-shadow-d: 0px 0px 2px rgba(0, 0, 0, 0.02),
    0px 3px 6px rgba(105, 130, 166, 0.02);
  --box-shadow-e: 0px 0px 20px rgba(0, 0, 0, 0.05),
    0px 6px 12px rgba(0, 0, 0, 0.1);
  --box-shadow-f: 0px 5 25px rgba(0, 0, 0, 0.05);
  --box-shadow-m: 0px 16px 10px -10px rgba(121, 190, 112, 0.2);

  --box-shadow-green: 0px 0px 20px #d5edd5, 0px 6px 12px #d5edd5;

  --transition-fast: 0.1s ease;
  --transition: 0.3s ease;
  --transition-slow: 0.6s ease;

  --main-font: 'Inter', sans-serif;
  --headings-font: 'Montserrat', sans-serif;

  --body-color: var(--color-black-primary);
  --body-bg: var(--grey-7);

  --container-max-width: 1216px;
  --container-padding: 20px;
  --menu-width: 0px;

  --input-height: 56px;
  --input-padding-y: 16px;
  --input-padding-x: 16px;
  --input-font-size: 1rem;
  --input-line-height: 1.25;
  --input-radius: var(--border-radius-l);
  --input-bg: var(--color-white-primary);
  --input-placeholder: var(--grey-2);
  --input-border: transparent;
  --input-hover-border: var(--grey-4);
  --input-focus-border: var(--primary-color);
  --input-icon-padding: 42px;
  --input-icon-size: 24px;

  --textarea-height: 132px;

  --select-dropdown-item-padding: 10px 16px;
}

@media (max-width: 1280px) {
  :root {
    --container-max-width: 964px;
  }
}

@media (max-width: 1024px) {
  :root {
    --container-max-width: 756px;
  }
}

@media (max-width: 800px) {
  :root {
    --container-max-width: 600px;
  }
}

@media (max-width: 575px) {
  :root {
    --container-padding: 15px;

    --input-height: 46px;
    --input-padding-y: 12px;
    --input-padding-x: 12px;
    --input-font-size: 13px;
    --input-radius: var(--border-radius-m);
  }
}

* {
  box-sizing: border-box;
}

::-webkit-input-placeholder,
::placeholder {
  color: var(--input-placeholder);
}

html,
body {
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  /* overflow-x: hidden; */
}

html {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

html > div[style] {
  display: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
  color: var(--body-color);
  background-color: var(--body-bg);
  /* overflow-x: hidden !important; */
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

a {
  color: var(--color-black-primary);
  text-decoration: none;
  transition: var(--transition);
}

a:hover,
a:focus {
  text-decoration: none;
}

input,
select,
textarea,
.content-editable {
  padding: var(--input-padding-y) var(--input-padding-x);
  border-radius: var(--input-radius);
  font-family: var(--main-font);
  font-size: var(--input-font-size);
  line-height: var(--input-line-height);
  background: var(--input-bg);
  border: 1px solid var(--input-border);
  outline: none;
  transition: border var(--transition), border-radius var(--transition), box-shadow var(--transition);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

input {
  height: var(--input-height);
}

textarea {
  height: var(--textarea-height);
}

input:hover,
textarea:hover {
  border-color: var(--input-hover-border);
}

input:focus,
textarea:focus {
  border-color: var(--input-focus-border);
}

input.grayBg {
  background: none;
  border-color: var(--grey-3);
}

input.grayBg:hover {
  border-color: var(--grey-2);
}

input.grayBg:focus {
  border-color: var(--primary-color);
}

input.error,
textarea.error {
  border-color: var(--error-color);
}

input.success,
textarea.success {
  border-color: var(--color-green-primary);
}

input[type='checkbox'] {
  -webkit-appearance: checkbox;
  height: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headings-font);
}

b {
  font-weight: 600;
}

button {
  font-family: var(--main-font);
  transition: all var(--transition);
  cursor: pointer;
}

.accent-text {
  color: var(--primary-color);
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: var(--container-max-width);
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}

.container.content {
  flex-grow: 1;
}

.container.wide {
  max-width: 100%;
}

.main-container {
  padding-top: 80px;
  /* overflow: hidden; */
  /* если это надо раскомментировать, нужно написать функцию для sticky кнопки DocumentHTML */
}

.main-container.full-width {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.skeleton-loader {
  position: relative;
  background: var(--grey-4);
  overflow: hidden;
}

.skeleton-loader::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    110deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 0)
  );
  transform: translateX(-100%);
  animation: shimmer 2s infinite;
  content: '';
}

.styled-scrollbar::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.styled-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  background: var(--primary-color-light);
  outline: none;
  border-radius: 5px;
}

.options-content {
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-b);
  border-radius: var(--border-radius-m);
  overflow-y: auto;
}

.options-content::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.options-content::-webkit-scrollbar-track {
  background: transparent;
}

.options-content::-webkit-scrollbar-thumb {
  background: var(--primary-color-light);
  outline: none;
  border-radius: 5px;
}

ul.slick-dots {
  position: static;
  margin-top: 20px;
  line-height: 0;
}

ul.slick-dots li {
  margin: 0 5px;
  width: auto;
  height: auto;
}

ul.slick-dots li button {
  padding: 3px;
  width: 6px;
  height: 6px;
  border-radius: var(--border-radius-xl);
  background: var(--grey-3);
  transition: none;
}

ul.slick-dots li button::before {
  content: none;
}

ul.slick-dots .slick-active button {
  width: 8px;
  height: 8px;
  margin: -1px 0;
  background: var(--primary-color);
}

[role='tooltip'].timezone-content {
  border: none;
  box-shadow: none;
  padding: 0;
}

@media (max-width: 800px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }

  html,
  body,
  #root {
    min-height: 100dvh;
  }
}